@import "@/components/common/var.less";

.githubQABubble :global {
  width: 100%;
  background-color: unset;

  .ListItem {
    background-color: transparent;

    &:first-child {
      background-color: unset;
    }
    &:last-child {
      background-color: unset;
    }
  }

  .question-type {
    color: rgba(var(--text-design-rgb-3), 1);
    padding: 4px;
  }

  .result-reply {
    padding: 0 10px;
    border-top: unset !important ;
  }

  .widthStyle {
    width: calc(100vw - 93px);
  }

  .githubCard {
    white-space: pre-wrap;
    hyphens: auto;

    .ant-descriptions {
      .ant-descriptions-header {
        margin-bottom: 0;

        .ant-descriptions-title {
          cursor: pointer;
          margin-right: 8px;
          display: flex;
          justify-content: space-between;

          .modified {
            color: rgba(var(--text-design-rgb-3), 1);
            font-size: 14px;
            font-weight: normal;
          }

          .ant-typography:hover {
            text-decoration: underline;
            text-decoration-color: var(--brand-1);
          }
        }

        .ant-typography {
          color: var(--brand-2);
          white-space: break-spaces;
        }

        .ant-descriptions-extra {
          .scrollbars {
            > div:not(:first-child) {
              div {
                background-color: var(--brand-2) !important;
              }
            }
          }

          .ant-popover {
            width: 100%;
            max-width: 1000px;
            padding: 0;

            .ant-popover-inner-content {
              height: 400px;
              width: 100%;
              padding: 0;

              .code-toolbar {
                position: sticky;
                width: 100%;
                opacity: 0;
                display: flex;
                justify-content: flex-end;
                gap: 8px;
                top: 8px;
                right: 8px;
                padding-right: 12px;

                > span {
                  color: var(--brand-3);
                  font-size: 18px;

                  &:hover {
                    color: var(--brand-2);
                  }
                }
              }

              .syntaxHighlighter {
                margin: -1.5em 0 !important;
                // height: 100%;
              }

              &:hover {
                .code-toolbar {
                  opacity: 1;
                }
              }
            }
          }

          .ant-btn {
            background-color: var(--brand-2);
            height: 36px;
            color: var(--white);
            font-size: 16px;
            border-radius: 4px;
            padding: 4px 8px;

            .ant-dropdown-menu {
              padding: 0;

              .ant-dropdown-menu-item {
                padding: 0;
              }
            }

            &:hover {
              border: @border-width solid var(--brand-2);
            }

            .disabled {
              &:hover {
                background-color: var(--brand-2);
                cursor: not-allowed;
              }
            }
          }
        }
      }

      .ant-descriptions-item {
        padding-bottom: 0;

        .ant-descriptions-item-label {
          color: rgba(var(--text-design-rgb-3), 1);
          display: flex;
          width: 70px;

          .label-name {
            flex: 1;
            text-align-last: justify;
          }
        }
        &:not(.abstract) .ant-descriptions-item-content {
          color: rgba(var(--text-design-rgb-2), 1);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .ant-typography {
          margin-bottom: 0;
          color: rgba(var(--text-design-rgb-2), 1);
        }
      }
    }
  }

  .ant-typography-expand {
    color: var(--link-color);
  }
}

@primary-color: #08979c;@font-size-base: 14px;