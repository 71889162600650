@import "~antd/dist/antd.less";
@import "@/components/common/var.less";

body {
  caret-color: var(--black);

  .ahooks-page-fullscreen {
    overflow: auto;
    font-size: 18px;
  }
  .MessageList {
    .Message + .Message {
      margin-top: 0;
    }
    // > :first-child {
    //   padding-bottom: 6px;
    // }
    // > :not(:first-child) {
    //   padding: 6px 0;
    // }

    .ant-typography {
      user-select: unset !important;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;