@import '@/components/common/var.less';

.placeHolderContainer{
	width: 100%;
	position: absolute;
	padding-top: 50px;
	overflow: hidden;
	max-width: 750px;
	max-height: 500px;
	left: 50%;
	transform: translateX(-50%);
}

@primary-color: #08979c;@font-size-base: 14px;