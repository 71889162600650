.editorSelectorWrapper :global {
  position: relative;

  .editorSelector {
    position: relative;
    display: flex;
    flex-flow: row;
    font-size: 13px;

    .ant-select {
      flex: 1 1 auto;
      max-width: calc(100vw - 52px);
      margin-left: 52px;
    }

    .ant-select-selection-placeholder {
      color: rgba(var(--text-design-rgb-2), .2);
      transform: translateX(-8px) translateY(-50%);
    }
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    padding: 5px 5px 5px 13px;
    color: rgba(var(--text-design-rgb-2), 0.75);
  }

  i.required {
    position: absolute;
    top: 5px;
    color: var(--status-error);
  }
}

@primary-color: #08979c;@font-size-base: 14px;