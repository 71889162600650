@import "@/components/common/var.less";

.sider :global {
  display: flex;
  flex-direction: column;
  z-index: 99;
  height: 100%;
  width: 300px;
  min-width: 300px;
  border-right: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
  border-left: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
  background: rgba(var(--sider-background-color-rgb), 0.7);
  top: 0;
  box-sizing: border-box;
  transition: all 0.8s ease;

  .sider-body {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    background: rgba(var(--sider-background-color-rgb), 0.7);
  }

  .sider-footer {
    height: 144px;
    padding: @gutter;

    .ant-btn {
      border-color: var(--brand-1);
      width: 100%;
      margin-bottom: @gutter;
      opacity: 0.88;
      transition-duration: 0.1s;

      span {
        backface-visibility: hidden;
        transition: all .28s;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        opacity: 1;
      }

      sup {
        transition-duration: 0.1s;
      }
    }

    @media only screen and (max-width: 768px) {
      height: 188px;
    }
  }
}

.shareModal :global {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .share-tip {
    margin-bottom: @gutter;
    span {
      color: var(--brand-1);
    }
  }
  .ant-skeleton-image {
    width: 200px;
    height: 200px;
  }
}

.serviceModal :global {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      color: var(--brand-1);
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.customerModal :global {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      color: var(--brand-1);
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;