.historyList :global {
  height: calc(100vh - 190px);
  width: 100%;

  .ant-empty-description {
    color: rgb(var(--text-design-rgb-3));
  }

  .scrolling-bottom {
    .history-section:last-child .history-item:last-child {
      &:after {
        content: '人家也是有底线的啦 @^_^@ ';
        position: absolute;
        padding: 10px 5px;
        left: 50%;
        transform: translate(-50%, 40px);
        color: rgba(var(--text-design-rgb-2), .35);
        width: 100%;
        text-align: center;
        padding: 32px 0 26px 0;
      }
    }
  }
}

.historyListWrapper :global {
  display: block;
}

.historySection :global {
  margin-bottom: 32px;
  h3 {
    font-family: "Microsoft Yahei";
    font-size: 18px;
    color: rgba(var(--gray-rgb-2), 1);
    font-weight: bold;
    margin-bottom: 8px;
  }
}

@primary-color: #08979c;@font-size-base: 14px;