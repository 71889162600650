@import "@/components/common/var.less";

.editorInputWrapper :global {
  position: relative;

  [data-placeholder]:empty:before{
    content: attr(data-placeholder);
    color: rgba(var(--text-design-rgb-2), .2);
    cursor: text;
  }

  .invalid [data-placeholder]:not(:focus):empty:before{
    color: rgba(var(--status-error-rgb), .7);
  }
  
  .editorInput {
    position: relative;

    &.h3 {
      font-size: 17px;
      line-height: 22px;

      h3 {
        font-size: 18px;
      }
    }

    &.h4 {
      font-size: 16px;
      line-height: 20px;

      h4 {
        font-size: 16px;
      }
    }
    &.h5 {
      font-size: 15px;
      line-height: 18px;

      h5 {
        font-size: 15px;
      }
    }
    &.h6 {
      font-size: 14px;
      line-height: 15px;

      h6 {
        font-size: 15px;
      }
    }
    &.p {
      font-size: 13px;
      line-height: 15px;

      p {
        font-size: 13px;
      }
    }
    

    &.h4,
    &.h5,
    &.h6 {
      label {
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }
    }
    
    h3 {
      text-indent: 60px;
    }
    
    h4 {
      text-indent: 48px;
      margin-bottom: 3px;
    }
    
    h5 {
      text-indent: 48px;
      margin-bottom: 2px;
    }
    
    h6 {
      text-indent: 48px;
      margin-bottom: 2px;
    }

    p {
      text-indent: 48px;
      margin-bottom: 0;
    }

    h3,
    h4,
    h5,
    h6,
    p {
      // font-weight: normal;
      color: rgba(var(--text-design-rgb-1), 1);
      padding: 5px 8px;
      &:focus-visible {
        outline: none !important;
      }
    }
  }
  .label {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    padding: 5px 5px 5px 13px;
    color: rgba(var(--text-design-rgb-2), 0.75);
  }

  i.required {
    position: absolute;
    top: 5px;
    color: var(--status-error);
  }

  .h3,
  .h4,
  .h5,
  .h6  {
    .label {
      font-weight: bold;
    }
  }
}


@primary-color: #08979c;@font-size-base: 14px;