.knowledgeModal :global {
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
    background: rgba(96, 165, 250, 0.8);
    .ant-modal-title {
      color: #fff;
    }
  }

  .ant-modal-body {
    position: relative;
  }

  .knowledgeModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  // css更换展开关闭图标
  .ant-tree-switcher_close{
    width: 14px;
    height: 21px;
    background: url('./plus.png') no-repeat;
    background-size: contain;
  } 
  .ant-tree-switcher_open{
      width: 14px;
      height: 21px;
      background: url('./minus.png') no-repeat;
      background-size: contain;
  } 

  .anticon.anticon-caret-down.ant-tree-switcher-icon{
      opacity: 0;
  }

  // 修改行布局
  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background-color: rgba(96, 165, 250, 0.8);
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before {
    background-color: rgba(96, 165, 250, 0.8);
  }
  
  // 选中节点内边距
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected {
    padding: 0 4px 4px 4px;
  }

  .noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .tip {
      display: flex;
      text-align: center;
      margin-bottom: 2px;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;