@import "@/components/common/var.less";

.windowTitle :global {
  flex: 1;
  max-width: calc((100% - 455px) / 2);
  line-height: @line-height-lg;
  display: flex;
  align-items: center;
  padding: 0 @gutter;

  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    > button {
      min-width: 32px;
    }

    .ant-input-search {
      input {
        padding: 0;
        font-size: @font-size-md;
        height: 32px;
        line-height: 32px;
      }
      .ant-input-group-addon {
        button {
          border-color: rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
          &:hover {
            // color: var(--brand-1);
            border-color: var(--brand-1);
          }
        }
      }
    }
    .title-text {
      // margin-right: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .title-back-icon {
      cursor: pointer;
      opacity: 0.7;
      padding: 7px 12px 7px 4px;
      display: inline-block;
      width: 32px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-right: 12px;
      
      &:hover {
        opacity: 1;
      }
    }

    .save-button {
      display: flex;
      gap: 8px;
      align-items: center;

      .anticon-save {
        color: rgba(var(--text-design-rgb-2), 1);
        height: 18px;
        line-height: 18px;
      }
      .save-info {
        min-width: 74px;
        font-size: 12px;
        color: rgba(var(--text-design-rgb-3), 1);
        > span {
          margin-right: 4px;
        }
      }
    }

    > span {
      cursor: pointer;
      &[aria-label="clear"] {
        color: rgba(var(--text-design-rgb-3), 1);
        cursor: pointer;
        margin-left: 8px;

        &:hover {
          color: var(--brand-1);
        }
      }
    }
  }
  .ant-popover {
    width: 100%;
    max-width: 380px;
    .ant-typography {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    width: calc(100% - 220px);
    max-width: unset;
    min-width: 100px;
  }
}

@primary-color: #08979c;@font-size-base: 14px;