.QuickRepliesModes :global {
  width: 100%;
  margin-bottom: 5px;
  
  .QuickReplies-Modes-inner {
    max-width: 884px;
    margin: 0 auto;
    padding: 0 15px;
    transition: opacity .3s;
    z-index: 100;
    @media only screen and (max-width: 768px) {
      .ant-radio-button-wrapper {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;