@import "@/components/common/var.less";

.topMessage :global {
  min-height: 23px;
  overflow: hidden;
  position: sticky;
  top: 0;
  
  .inner {
    // max-width: 900px;
    padding: @gutter 47px @gutter 47px ;
    // padding: 0 47px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: height .28s;
    position: relative;

    b {
      word-break: keep-all;
    }
  }

  // &.collapse {
  //   padding: 0;
  //   min-height: 23px;
  // }

  .inner.collapse {
    margin-top: 0;
    height: 20px;
    padding-top: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
    overflow: hidden;
  }

  .handler {
    display: block;
    text-align: center;

    &.collapse {
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      svg {
        transform: rotate(0);
        position: relative;
        top: 2px;
      }
    }

    svg {
      transition: all .28s;
      transform: rotate(180deg);
      opacity: .78;
    }
  }

  @media only screen and (max-width: 768px) {
    .inner {
      margin-top: 25px;
      padding: 0 @gutter !important;

      b {
        display: none;
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;