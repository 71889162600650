@import "@/components/common/var.less";

.chatLayout :global {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  // flex-direction: column;
}

.chatBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@primary-color: #08979c;@font-size-base: 14px;