.BottomMenu :global {
  width: 100%;
  height: 60px;

  .bottomMenu-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    border-top: 1px solid var(--brand-1);

    .bottomMenu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgba(var(--text-design-rgb-1), 0.9);
      font-weight: 600;
      color: rgba(var(--gray-rgb-1), 0.6);

      svg {
        font-size: 19px;
      }

      span {
        margin-top: 5px;
      }

      &.active {
        color: var(--brand-1);
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;