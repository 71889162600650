.historyOverlay :global {
  position: fixed;
  z-index: 900;
  width: calc(100vw - 60px);
  left: 60px;
  top: 0;
  height: 100vh;
  background-color: rgba(var(--gray-rgb-6), 1);

  .historyContainer {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

  .historyHeader {
    height: 100px;
    text-align: center;
    font-size: 32px;
    color: rgba(var(--gray-rgb-2), 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: "Microsoft Yahei";
  }

  .historySearch {
    height: 42px;
    background-color: rgba(var(--background-color-rgb), 1);
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 0 30px 0;

    .ant-input-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-input-group-addon {
      border: none !important;
      display: inline-flex      ;
      align-items: center;
      justify-content: center;
      width: 50px;
      flex-shrink: 0;
      
    }
    .ant-input-affix-wrapper {
      margin-top: 1px;
    }
    .ant-input-affix-wrapper,
    & + .ant-input-affix-wrapper {
      border: none !important;
      height: 40px;
      line-height: 40px;
      input:active,
      input:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;