.ScrollToBottom {
  position: absolute;
  right: 9px;
  bottom: 120px;
  z-index: 10;
  width: 36px !important;
  height: 36px !important;
  cursor: pointer;
  background-color: rgba(96, 165, 250, 1);
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  &:hover {
    background-color: rgba(96, 165, 250, 0.85);
  }

  .scroll-to-bottom-content {
    overflow: hidden;
    text-align: center;
    border-radius: 20px;
    transition: all 0.3s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@primary-color: #08979c;@font-size-base: 14px;