@import "@/components/common/var.less";

.windowToolbar :global {
  flex: 1;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: rgba(var(--text-design-rgb-3), 1);
  width: 60px;
  padding-right: @gutter;

  .user-avatar {
    cursor: pointer;
  }

  .auth-button {
    border: unset;
    color: var(--brand-1) !important;
    padding-right: 0;

    span {
      line-height: 16px;
      border-bottom: 1px solid var(--brand-1);
    }

    :hover {
      color: var(--brand-1);
      border-color: var(--brand-1);
    }
  }

  .login-gift {
    margin-top: 6px;
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }

  .left-count {
    color: var(--brand-1) !important;

    > span {
      line-height: 16px;
      border-bottom: 1px solid var(--brand-1);
    }

    :hover {
      color: var(--brand-1);
      border-color: var(--brand-1);
    }
  }
  @media only screen and (max-width: 768px) {
    flex: unset;
  }
}

// 用户信息弹框样式
.userPopover :global {
  width: 332px;
  .ant-popover-inner {
    border-radius: 10px;
    .ant-popover-inner-content {
      padding: 10px;
    }
  }
}

.UserPopContent :global {
  .pop-content-info {
    height: 60px;
    display: flex;
    align-items: center;
    gap: 5px;

    .user-info {
      display: flex;
      flex-direction: column;
      .user-name {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 21.72px;
        color: var(--name-color);
      }
      .user-id {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 17.38px;
        .user-id-text{
          color: rgba(128, 128, 128, 1);
        }
        svg {
          margin-left: 5px;
          cursor: pointer;
          fill: rgba(189, 210, 239, 1);
        }
      }
    }

  }
  .pop-content-member {
    .current-member {
      position: relative;
      height: 51px;
      opacity: 1;
      border-radius: 6px;
      background: linear-gradient(135deg, rgba(3, 167, 255, 0.6) 0%, rgba(41, 141, 255, 0.44) 57.64%, rgba(87, 165, 255, 0.6) 100%);
      
      .member-points {
        position: absolute;
        top: 14px;
        left: 10px;
        .member-type {
          font-size: 16px;
          letter-spacing: 0px;
          line-height: 20.8px;
          color: rgba(17, 58, 242, 1);
          font-family: "YouSheBiaoTiHei";
          // -webkit-text-stroke: 1px rgba(17, 58, 242, 1);
        }
        .member-end-time {
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 18.82px;
          color: rgba(17, 58, 242, 1);
        }
        .member-points-count {
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 18.82px;
          color: rgba(128, 128, 128, 1);
        }
      }
      .member-btn {
        position: absolute;
        right: 9px;
        bottom: 8px;
        width: 86px;
        height: 21px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(17, 58, 242, 1);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 21px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        cursor: pointer;
      }
    }

    .member-benefits {
      margin-top: 10px;
      .benefit-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        margin-bottom: 5px;
        height: 33px;
        border-radius: 5px;
        box-shadow: 0px 1px 2px  rgba(0, 0, 0, 0.2);
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 17.38px;
        .item-info {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .item-desc {
          font-weight: 500;
          opacity: 0.6;
        }
        .item-use {
          font-weight: 700;
          opacity: 0.6;
        }
      }
    }
  }
  .pop-content-btn {
    margin-top: 18px;
    cursor: pointer;
    .btn-item {
      height: 33px;
      display: flex;
      align-items: center;
      gap: 15px;
      border-radius: 5px;
      padding: 4px 6px;
      color: var(--btn-color);
      .name {
        font-size: 12px;
      }
      &:hover {
        background: var(--btn-hover-color);
        // border: 1px solid rgba(96, 165, 250, 1);
        box-shadow: 0 0 0.3px 0.3px rgba(96, 165, 250, 1) inset;
      }
    }

    .line {
      position: relative;
      border: none;
      background-color: transparent;
      height: 1px;
    }
    
    .line::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(128, 128, 128, 1);
      transform: scaleY(0.1);
      transform-origin: center;
    }
  }
}

.GuestPopContent :global {
  .header {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 20.27px;
    margin-bottom: 10px;
  }
  .introduction-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .introduction-content-item {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 10px;
      span {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 17.38px;
        color: rgba(128, 128, 128, 1);
      }
    }
  }

  .btn {
    text-align: center;
    .login-btn {
      width: 183px;
      border-radius: 5px;
    }
  }
}

.infoPopover :global {
  width: 250px;
  .ant-popover-inner {
    border-radius: 10px;
    .ant-popover-inner-content {
      padding: 10px 10px 3px 10px;
    }
  }
}

.PointsPopContent :global {
  .item {
    display: flex;
    gap: 4px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 17.38px;
    margin-bottom: 6px;
  }
}


.settingbtn :global {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
    }
  }

  .ant-modal-body {
    padding-bottom: 0;
  }
  .anticon[aria-label="team"] {
    padding: 8px;
    font-size: @font-size-md;
    position: absolute;
    right: -32px;
  }
  .ant-space {
    width: 100%;
    .tip {
      color: rgba(var(--text-design-rgb-3), 1);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
  .ant-popover {
    width: 100%;
  }
  img {
    width: 100%;
    background-color: var(--brand-1);
  }
}

// 登陆
.authorization :global {
  .ant-modal {
    overflow: hidden;
    padding-bottom: 0;
  }

  .ant-modal-close-x {
    svg {
      width: 20px;
      height: 20px;
      fill: rgba(96, 165, 250, 1);
    }
  }

  .ant-modal-body {
    position: relative;
    height: 432px;
    padding: 0px;

    .ant-skeleton {
      .ant-skeleton-image {
        width: 150px;
        height: 150px;
      }
    }
  }

  .login-wrapper {
    display: flex;
    height: 100%;
    .login-left {
      width: 43%;
      height: 100%;
      border-radius: 20px 0 0 20px;
      background: linear-gradient(101.95deg, rgba(96, 165, 250, 1) 0%, rgba(177, 211, 252, 0.71) 100%);
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center; 
      img {
        width: 206px;
        height: 243px;
        opacity:0.6;
        object-position: 0 20px;
      }
      .login-tips {
        max-width: 200px;
        .login-tip {
          display: flex;
          svg {
            margin-right: 10px;
            margin-top: 3px;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 18px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            margin-bottom: 0px;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 18px;
            color: rgba(0, 58, 125, 1);
          }
        }
      }
    }

    .full-width {
      width: 100%;
    }
    .right-width {
      width: 66%;
    }

    .login-right {
      height: 100%;
      border-radius: 0 20px 20px 0;
      .login-content {
        width: 100%;
        height: 398px;
        padding:  50px 5px 0 5px;
        text-align: center;

        .login-title {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 26.06px;
          color: rgba(96, 165, 250, 1);
          text-decoration: underline;
          text-underline-offset: 8px;
          margin-bottom: 8px;
        }

        .login-subtitle {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20px;
          color: rgba(96, 165, 250, 0.5);
        }

        .login-form {
          width: 100%;
          height: 227px;
          margin-top: 10px;

          .weixin-login {
            display: flex;
            flex-direction: column;
            align-items: center;
            .login-qr-tip {
              width: 165px;
              font-size: 13px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 18.82px;
              color: rgba(0, 0, 0, 1);
  
            }
          }
          .login-form-other {
            width: 100%;
            padding: 0px 60px;

            // form 手机/邮箱输入 圆角和高度设置
            .input-phone {
              .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
                height: 36px;
  
                &::placeholder {
                  font-size: 14px !important;
                }
              }
  
              .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
  
                &::placeholder {
                  font-size: 14px !important;
                }
              }
            }

            .input-email {
              border-radius: 5px;
              height: 36px;

              &::placeholder {
                font-size: 14px;
              }
            }

            .input-code {
              border-radius: 5px;
              height: 36px;

              &::placeholder {
                font-size: 14px;
              }
            }

            .btn-code {
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 17.38px;
              border-radius: 5px;
              height: 36px;
              padding-left: 12px;
              padding-right: 12px;
            }

            .ant-btn-primary[disabled],.ant-btn-primary[disabled]:hover {
              background: rgba(204, 204, 204, 1);
              color: rgba(0, 0, 0, 1);
              border: none;
            }

            // 登陆按钮圆角
            .ant-btn-block {
              border-radius: 5px;
              height: 36px;
            }
          }
          
        }
        .login-tab {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
  
          .login-tab-item {
            width: 120px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #505050;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 3px;
            }
          }
        }
      }
      .login-message {
        font-size: 12px;
      }
      .login-footer {
        width: 100%;
        height: 34px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20px;
          margin-bottom: 0px;
          margin-left: 6px;
          a {
            margin: 0 3px;
          }
        }
      }
      .pc-bg {
        background: rgba(229, 229, 229, 0.5);
      }
      .mobile-bg {
        background: rgba(96, 165, 250, 0.7);
        border-bottom-left-radius: 20px;
      }
    }
    .login-tip {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #505050;
      margin-top: 10px;
    }
  }
  

  .qrcode-wrapper {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    position: relative;
  }

  .qrcode-btn {
    top: 50%;
    left: 50%;
    z-index: 2;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    padding-left: 5px;
    padding-right: 5px;
  }
}

.avatarDropdown :global {
  .ant-dropdown-menu-item {
    padding: 5px;
    .ant-btn-text:hover {
      background-color: unset;
    }
  }
  .ant-dropdown-menu-item-divider {
    margin: 0;
    background-color: rgba(var(--bg-design-rgb-5), 1);
  }

  .user-name {
    font-size: 15px;
    width: 100%;
    display: block;
    word-break: keep-all;
    white-space: nowrap;
    padding: 4px 15px;
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.noticeModal :global {
  height: 100%;
  // .notice-contain{

  // }
}

.confirmModal :global {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      color: var(--brand-1);
      overflow: hidden;
    }
  }
}

.modeSwitch :global {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  svg {
    fill: var(--brand-1); 
    width: 22px;
    height: 22px;
  }
}

@primary-color: #08979c;@font-size-base: 14px;