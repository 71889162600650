[data-theme="dark"],
[data-theme="light"] {
  --antd-wave-shadow-color: var(--brand-1);

  *::selection {
    background-color: var(--brand-1);
  } 

  .ant-spin-dot-item {
    background-color: var(--brand-1);
  }

  button.ant-btn-link {
    color: var(--brand-1);
    
    &:hover {
      color: var(--brand-1);
    }
  }
  
  a {
    color: var(--brand-2);
    &:hover {
      color: var(--brand-2);
    }
  }

  .ant-btn-dangerous.ant-btn-text,
  .ant-btn-dangerous.ant-btn-text,
  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: var(--status-error);
  }

  .ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):focus,
  .ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):active {
    color: var(--brand-1);
  }

  .ant-btn.ant-btn-primary:focus,
  .ant-btn.ant-btn-primary:active {
    color: var(--white);
  }

  .ant-switch-checked {
    background-color: var(--brand-1);
  }
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    border-color: var(--brand-1);
  }

  .ant-btn-primary {
    opacity: 0.88;

    .ant-upload {
      color: var(--white);
    }
  }
  .ant-btn-primary:hover {
    opacity: 1;
  }

  .ant-btn-primary,
  .ant-btn-primary:hover {
    color: var(--white);
    border-color: var(--brand-1);
    background: var(--brand-1);
  }
  
  .ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
    background-color: rgba(var(--white), --border-design-opacity-1);
  } 

  .ant-radio-button-wrapper:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .ant-radio-button-wrapper:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: var(--white);
    border-color: transparent;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: var(--brand-1);
    color: var(--white);
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: transparent;
  }
  .ant-radio-button-wrapper:not(:first-child):hover::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: transparent;;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    border-color: var(--brand-1);
    color: var(--brand-1);
    a {
      color: var(--brand-1);
    }
  }

  .ant-checkbox {
    & .ant-checkbox-inner,
    &:hover .ant-checkbox-inner {
      border-color: var(--brand-1);
    }
    &:hover,
    &:after {
      border-color: var(--brand-1);
    }
  }
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--brand-1);
    border-color: var(--brand-1);
  }

  .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--brand-1);
  }

  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .ant-slider {
    .ant-slider-handle {
      border-color: var(--brand-1);
    }
  
    .ant-slider-track {
      background-color: var(--brand-1);
    }

    &:hover {
      .ant-slider-handle {
        border-color: var(--brand-1);
      }
    
      .ant-slider-track {
        background-color: var(--brand-1);
      }
    }
  }

  button.ant-btn-default {
    &:hover {
      border-color: var(--brand-1);
      color: var(--brand-1);
    }
  }

  button.ant-btn-primary:not(.ant-btn-compact-item, .ant-btn-icon-only) {
    background-color: var(--brand-1);
    border-color: var(--brand-1);
    opacity: 0.88;
    &:hover {
      border-color: var(--brand-1);
      background-color: var(--brand-1);
      opacity: 1;
    }
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: var(--brand-1);
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: var(--brand-1);
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
    border-color: var(--brand-1);
  }

  .ant-popover-buttons {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before,
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before {
    background-color: var(--brand-1);
  }
}
