@import "@/components/common/var.less";
@import "~antd/es/style/themes/default.less";

.bubbleLeftWrapper :global {
  width: 100%;
  display: flex;
  margin-right: 0;
  background-color: rgba(var(--message-background-color-rgb), var(--opacity) );
  border-radius: 8px;

  .typing-cursor {
    display: inline-block;
    width: 1px;
    height: 16px;
    background-color: #333333;
    animation: 0.75s text-cursor infinite;
    position: relative;
    top: 3px;
    margin: 0 0 0 4px;
  }

  .bubble-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: transparent;
    width: 100%;
    position: relative;

    .bubble-checkbox-wrapper {
      position: absolute;
      right: 0;
      top: 0;
    }

    .Bubble {
      max-width: 100% !important;
    }

    >div.ant-space {
      width: 100%;

      >div {
        width: 100%;

        >div.Bubble {
          max-width: 100% !important;
        }
      }
    }



    .text {
      width: 100%;
      margin-right: 0;
    }

    .image-bubble {
      background-color: transparent;
      padding: 0;

      &.loading {
        .ant-image {
          min-height: 100px;
          border-radius: 3px;
        }

        .ant-image-placeholder {
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          color: rgba(var(--text-design-rgb-2), .5);
          font-style: normal;

          .ant-spin-spinning {
            margin-right: 15px;
          }
        }
      }

      &.error {
        .ant-image-placeholder {
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 25px;
          height: 25px;
        }
        .ant-image-img {
          background-color: rgba(var(--bg-design-rgb-3), .1);
          border-radius: 3px;
          min-height: 100px;
        }
      }
    }

    .error-info {
      color: var(--status-error);

      span {
        color: var(--brand-1);
        cursor: pointer;
      }
    }

    .ant-skeleton {
      .ant-skeleton-content {
        // border-radius: 12px;
        background: transparent;
        padding: 0;

        .ant-skeleton-title {
          margin-bottom: 0;
        }

        .ant-skeleton-paragraph {
          width: 100%;
        }
      }
    }

    &.loading-bubble {
      width: 87%;

      .ant-skeleton-content {
        padding: 12px;
      }
    }

    .ant-skeleton-active .ant-skeleton-title::after,
    .ant-skeleton-active .ant-skeleton-paragraph>li::after,
    .ant-skeleton-active .ant-skeleton-avatar::after,
    .ant-skeleton-active .ant-skeleton-button::after,
    .ant-skeleton-active .ant-skeleton-input::after,
    .ant-skeleton-active .ant-skeleton-image::after {
      background: linear-gradient(90deg,
        rgba(var(--bg-design-rgb-5), 0.4) 25%,
        rgba(var(--bg-design-rgb-4), 0.4) 37%,
        rgba(var(--bg-design-rgb-5), 0.4) 63%
      );
    }
  }

  .markdown-body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.markdown-text-error,
    .text-error {
      color: var(--status-error);
    }

    >* {
      width: 100%;
    }

    >p {
      word-break: break-all;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }

    table {
      border-collapse: collapse;
      font-size: 0.9em;
      font-family: sans-serif;
      // box-shadow: 0 0 20px rgba(var(--black-rgb), 0.15);
      table-layout: fixed;
      // max-width: 656px;
      min-width: 100%;
      position: relative;
      border-left: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
      border-right: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));

      .expand-icon {
        height: 0;
        min-height: 0;

        td {
          padding: 0;
        }
      }

      span[aria-label="expand"] {
        position: absolute;
        left: 10px;
        top: 10px;
        color: rgba(var(--text-design-rgb-8), 1);
        font-size: 1.5rem;
        display: none;

        :hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      &:hover {
        >.expand-icon {
          span {
            display: block;
          }
        }
      }

      thead {
        th {
          word-break: keep-all;
          word-wrap: normal;
          white-space: nowrap;
        }

        tr {
          background-color: var(--brand-1);
          color: var(--white);
          text-align: left;
        }
      }

      tbody {
        tr {
          border-bottom: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));

          &:nth-of-type(even) {
            background-color: rgba(var(--bg-design-rgb-7), 0.3);
          }

          &:last-of-type {
            // border-bottom: 2px solid var(--brand-2);
          }
        }
      }

      th,
      td {
        padding: 12px 15px;
        vertical-align: top;
      }
    }

    .codeWrapper {
      position: relative;
      
      code > span {
        display: block !important;
        word-wrap: break-word !important;
        white-space: pre !important;
      }
    }

    .markmapWrapper {
      position: relative;
    }

    .markmap {
      min-height: 200px;
      .mm-toolbar {
        width: 146px;
        position: absolute;
        right: 0px;
        top: 2px;
        div[title="Toggle recursively"] {
          display: none;
        }
        div[title="export"] {
          cursor: pointer;
          font-size: .8125rem;
          padding: 3px 2px;
        }
        a{
          display: none;
        }
      }
    }

    pre {
      white-space: pre-line;
      word-wrap: break-word;
      overflow: auto;
      position: relative;
      word-break: break-all;

      >div {
        min-width: 400px;
      }

      &:hover {
        span[role="markdown-copy"] {
          display: block;
        }
      }

      .mermaid {
        width: 0;
      }
      
      span[role="markdown-copy"] {
        position: absolute;
        right: 12px;
        top: 16px;
        color: rgba(var(--text-design-rgb-4), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: var(--white);
        }
      }

      span[role="markdown-mermaid-copy"]{
        position: absolute;
        right: 30px;
        top: 16px;
        color: rgba(var(--text-design-rgb-2), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: rgba(var(--text-design-rgb-1), 1);
        }
      }

      &:hover {
        >span {
          display: block;
        }
      }

      span[role="markdown-mermaid-export"]{
        position: absolute;
        right: 12px;
        top: 16px;
        color: rgba(var(--text-design-rgb-2), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: rgba(var(--text-design-rgb-1), 1);
        }
      }

      &:hover {
        >span {
          display: block;
        }
      }
    }

    code {
      white-space: pre-wrap !important;
      word-wrap: break-word;
      overflow: auto;
    }

    .katex .base {
      white-space: unset;
      width: unset;
    }

    img {
      width: 100%;
      object-fit: contain;
    }

    ul,
    ol {
      li {
        white-space: normal;
        word-break: break-all;
      }
    }

    blockquote {
      color: rgba(var(--text-design-rgb-2), 1);
      margin: 0;
      padding-left: 1em;
      border-left: 0.2em #eee solid;
    }
  }

  @media only screen and (max-width: 768px) {
    .bubble-container {
      max-width: 100%;

      >.ant-space {
        .ant-space-item {
          max-width: 100%;
        }
      }
    }

    .markdown-body {
      pre {
        span[role="markdown-copy"] {
          display: block;
        }

        span[role="markdown-mermaid-copy"] {
          display: block;
        }

        span[role="markdown-mermaid-export"] {
          display: block;
        }
      }

      span[aria-label="expand"] {
        display: block !important;
      }
    }
  }
}

.bubbleRightWrapper :global {
  width: 100%;
  display: flex;
  // flex-direction: row-reverse;
  // margin-left: 40px;

  .bubble-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: transparent;

    >div.ant-space {
      width: 100%;

      >div {
        width: 100%;

        >div.Bubble {
          max-width: 100% !important;
        }
      }
    }

    .Bubble {
      max-width: 100% !important;
    }

    .text {
      width: 100%;
      margin-left: 0;

      .file-list {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 200px;
        padding: 8px 0;
        gap: 16px;
        cursor: pointer;
        margin-bottom: 5px;

        .file-bubble {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          color: rgba(var(--text-design-rgb-2), 1);

          .file-type {
            // background-color: var(--brand-1);
            // color: var(--white);
            // width: 60px;
            border-radius: 8px 0 0 8px;
            // text-align: center;
            padding: 6px;
            // font-weight: 600;
            margin-top: 4px;
          }

          .file-name {
            padding: 8px;
            flex: 1;
            border-radius: 0 8px 8px 0;
            max-width: 150px;
          }

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        .ant-image-img {
          box-shadow: var(--shadow-1) !important;
        }
      }
    }

    .ant-skeleton {
      display: flex;
      flex-direction: row-reverse;

      .ant-skeleton-header {
        padding-right: 0;
        padding-left: 16px;
      }

      .ant-skeleton-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // border-radius: 12px;
        background: transparent;
        padding: 12px;
        width: 100%;

        .ant-skeleton-title {
          margin-bottom: 0;
        }

        .ant-skeleton-paragraph {
          margin-top: 16px;
          width: 100%;
        }
      }
    }

    &.loading-bubble {
      width: 50%;

      .ant-skeleton-content {
        padding: 12px;
      }
    }

    .ant-skeleton-active .ant-skeleton-title::after,
    .ant-skeleton-active .ant-skeleton-paragraph>li::after,
    .ant-skeleton-active .ant-skeleton-avatar::after,
    .ant-skeleton-active .ant-skeleton-button::after,
    .ant-skeleton-active .ant-skeleton-input::after,
    .ant-skeleton-active .ant-skeleton-image::after {
      background: linear-gradient(90deg,
        rgba(var(--bg-design-rgb-5), 0.4) 25%,
        rgba(var(--bg-design-rgb-4), 0.4) 37%,
        rgba(var(--bg-design-rgb-5), 0.4) 63%
      );
    }
  }

  .markdown-body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    >* {
      width: 100%;
    }

    >p {
      word-break: break-all;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }

    table {
      border-collapse: collapse;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0 0 20px rgba(var(--black-rgb), 0.15);
      table-layout: fixed;
      // max-width: 656px;
      width: 100%;
      position: relative;

      .expand-icon {
        height: 0;
        min-height: 0;

        td {
          padding: 0;
        }
      }

      span[aria-label="expand"] {
        position: absolute;
        right: 8px;
        top: 8px;
        color: rgba(var(--text-design-rgb-2), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: rgba(var(--text-design-rgb-1), 1);
        }
      }

      &:hover {
        >.expand-icon {
          span {
            display: block;
          }
        }
      }

      thead {
        th {
          word-break: keep-all;
          word-wrap: normal;
          white-space: nowrap;
        }
        tr {
          background-color: var(--brand-1);
          color: var(--white);
          text-align: left;
        }
      }

      tbody {
        tr {
          border-bottom: @border-width solid rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));

          &:nth-of-type(even) {
            background-color: rgba(var(--bg-design-rgb-7), 0.3);
          }

          &:last-of-type {
            border-bottom: 2px solid var(--brand-2);
          }
        }
      }

      th,
      td {
        padding: 12px 15px;
        vertical-align: top;
      }
    }

    pre {
      white-space: pre-line;
      word-wrap: break-word;
      overflow: auto;
      position: relative;
      word-break: break-all;

      >div {
        min-width: 400px;
        
        img,
        svg,
        video {
          max-width: 835px;
        }
      }

      span[role="markdown-copy"] {
        position: absolute;
        right: 12px;
        top: 16px;
        color: rgba(var(--text-design-rgb-4), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: var(--white);
        }
      }

      span[role="markdown-mermaid-copy"] {
        position: absolute;
        right: 30px;
        top: 16px;
        color: rgba(var(--text-design-rgb-2), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: rgba(var(--text-design-rgb-1), 1);
        }
      }

      &:hover {
        >span {
          display: block;
        }
      }

      span[role="markdown-mermaid-export"] {
        position: absolute;
        right: 12px;
        top: 16px;
        color: rgba(var(--text-design-rgb-2), 1);
        font-size: @font-size-md;
        display: none;

        :hover {
          color: rgba(var(--text-design-rgb-1), 1);
        }
      }

      &:hover {
        >span {
          display: block;
        }
      }
    }

    code {
      white-space: pre-wrap !important;
      word-wrap: break-word;
      overflow: auto;
    }

    .katex .base {
      white-space: unset;
      width: unset;
    }

    img {
      width: 100%;
      object-fit: contain;
    }

    ul,
    ol {
      li {
        white-space: normal;
        word-break: break-all;
      }
    }

    blockquote {
      color: rgba(var(--text-design-rgb-2), 1);
      margin: 0;
      padding-left: 1em;
      border-left: 0.2em #eee solid;
    }
  }

  @media only screen and (max-width: 768px) {
    .bubble-container {
      max-width: 100%;

      >.ant-space {
        .ant-space-item {
          max-width: 100%;
        }
      }
    }

    .markdown-body {
      pre {
        span[role="markdown-copy"] {
          display: block;
        }

        span[role="markdown-mermaid-copy"] {
          display: block;
        }

        span[role="markdown-mermaid-export"] {
          display: block;
        }
      }

      span[aria-label="expand"] {
        display: block !important;
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;