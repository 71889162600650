.internetBtn {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.rotating {
  animation:spin 3s linear infinite;
}

@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

@primary-color: #08979c;@font-size-base: 14px;