@import "@/components/common/var.less";

.pubmedBubble :global {
  width: 100%;
  background-color: unset;

  .ListItem {
    background-color: transparent;

    &:first-child {
      background-color: unset;
    }
    &:last-child {
      background-color: unset;
    }
  }

  .result-reply {
    padding: 0 10px;
    border-top: unset !important ;
  }
  .widthStyle {
    width: calc(100vw - 93px);
  }
  .pubmedCard {
    white-space: pre-wrap;
    hyphens: auto;

    .header {
      font-size: 16px;
      padding: 0 4px;
      display: flex;
      justify-content: space-between;
      gap: @gutter;
      color: var(--brand-1);

      .title {
        flex: 1;
        cursor: pointer;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .publish {
        width: 100px;
        text-align: right;
      }
    }
    .intruduce {
      font-size: 7/8rem;
      color: rgba(var(--text-design-rgb-3), 1);
      display: flex;
      .authors {
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        .author {
          margin-right: 4px;
        }
      }
    }

    .journal {
      font-size: 7/8rem;
      color: rgba(var(--text-design-rgb-3), 1);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }

    .abstract {
      .abstract-content {
        > div {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          color: rgba(var(--text-design-rgb-4), 1);
        }
      }
      .keyword {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        color: rgba(var(--text-design-rgb-4), 1);
      }
      strong {
        color: rgba(var(--text-design-rgb-3), 1);
      }
    }
    .copyright {
      color: rgba(var(--text-design-rgb-3), 1);
    }
  }

  .ant-typography {
    margin-bottom: 0;
    color: rgba(var(--text-design-rgb-2), 1);
  }
}

@primary-color: #08979c;@font-size-base: 14px;