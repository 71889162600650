@import "@/components/common/var.less";
.chatBottom {
  width: 100%;
  position: absolute;
  bottom: 10px;
  z-index: 2;

  .chatBottomContainer {
    position: relative;
    &.dragging {
      background-color: rgba(var(--input-background-color-rgb), 0.25);
      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '拖拽文件到这里上传';
        color: #fff;
        text-align: center;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rgba(0,0,0, 0.23);
        border-radius: 12px;
        z-index: 999;
      }
    }
    &.loading {
      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '文件上传中...';
        color: #fff;
        text-align: center;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0, 0.23);
        border-radius: 12px;
        z-index: 999;
      }
      }
    }

  .chatBottomContainer :global {
    max-width: 884px;
    margin: 0 auto;
    // border: 1px solid var(--input-boder-color);
    box-shadow: 0px 0px 0px 0.6px var(--input-boder-color);
    border-radius: 12px;
    background-color: rgba(var(--input-background-color-rgb), 0.05);
    
    .fileList {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      .file-list-item-pic {
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        position: relative;
        > span[aria-label="close-circle"] {
          position: absolute;
          right: -6px;
          top: -6px;
        }
      }

      .file-list-item {
        width: 200px;
        border: @border-width solid rgba(var(--border-design-rgb-9), var(--border-design-opacity-1));
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        position: relative;

        .file-type {
          padding: 8px;
          &.error {
            border: @border-width solid var(--status-error);
            background-color: var(--status-error);
          }
        }

        .file-name {
          flex: 1;
          padding: 8px;
          border-radius: 0 8px 8px 0;
          white-space:nowrap;
          text-overflow: ellipsis;
          overflow:hidden;
          &.error {
            border: @border-width solid var(--status-error);
            color: var(--status-error);
            ~ span {
              color: var(--status-error);
            }
          }
        }
        > span[aria-label="close-circle"] {
          position: absolute;
          right: -6px;
          top: -6px;
        }
      }
    }
  
    .chatBottom-input {
      position: relative;
      .ant-input {
        // border-radius: 12px 12px 0 0;
        border: unset;
        background-color: rgba(var(--input-background-color-rgb), 0.05);
        
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .input-spinner {
        position: absolute;
        top: 14px;
        right: 30px;
        display: none;
        z-index: 3; //输入框index2 spinner index3
      }
    }
  
    .chatBottom-custom-toolbar {
      width: 100%;
      height: 40px;
      padding: 0 5px;
      border-radius: 12px 12px 0 0;
      background-color: rgba(var(--input-background-color-rgb), 0.05);
      display: flex;
      align-items: center;
      // justify-content: space-between;
  
      // .chatBottom-custom-toolbar-items {
      //   display: flex;
      //   align-items: center;
      // }
    }

    .chatBoottom-sendBtn {
      margin-bottom: 5px;
      margin-right: 5px;
      position: absolute;
      right: 0;
      bottom: 0;
      svg {
        position: relative;
        left: 1px;
        transform: rotate(0deg);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .chatBottom {
    padding: 0 5px;
  }
}

@primary-color: #08979c;@font-size-base: 14px;