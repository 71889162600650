@import "@/components/common/var.less";
@import "~antd/es/style/themes/default.less";
@import "./theme-reset.less";
@import "./theme-custom-dark.less";
@import "./theme-custom-light.less";


:root {
  --brand-1: rgb(96, 165, 250);
  --brand-rgb-1: 96, 165, 250;
  --brand-2: rgb(68, 150, 249);
  --brand-rgb-2: 68, 150, 249;
  --brand-3: rgb(230, 255, 251);
  --brand-rgb-3: 230, 255, 251;

  --brand-payment-rgb-1: 184,129, 63;
  --brand-payment-rgb-2: 237, 209, 177;

  --text-design-rgb-1: var(--gray-rgb-1);
  --text-design-rgb-2: var(--gray-rgb-2);
  --text-design-rgb-3: var(--gray-rgb-3);
  --text-design-rgb-4: var(--gray-rgb-4);
  --text-design-rgb-5: var(--gray-rgb-5);
  --text-design-rgb-6: var(--gray-rgb-6);
  --text-design-rgb-7: var(--gray-rgb-7);
  --text-design-rgb-8: var(--gray-rgb-8);
  --text-design-rgb-9: var(--gray-rgb-9);

  --bg-design-rgb-1: var(--gray-rgb-1);
  --bg-design-rgb-2: var(--gray-rgb-2);
  --bg-design-rgb-3: var(--gray-rgb-3);
  --bg-design-rgb-4: var(--gray-rgb-4);
  --bg-design-rgb-5: var(--gray-rgb-5);
  --bg-design-rgb-6: var(--gray-rgb-6);
  --bg-design-rgb-7: var(--gray-rgb-7);
  --bg-design-rgb-8: var(--gray-rgb-8);
  --bg-design-rgb-9: var(--gray-rgb-9);

  --border-design-rgb-1: var(--gray-rgb-1);
  --border-design-rgb-2: var(--gray-rgb-2);
  --border-design-rgb-3: var(--gray-rgb-3);
  --border-design-rgb-4: var(--gray-rgb-4);
  --border-design-rgb-5: var(--gray-rgb-5);
  --border-design-rgb-6: var(--gray-rgb-6);
  --border-design-rgb-7: var(--gray-rgb-7);
  --border-design-rgb-8: var(--gray-rgb-8);
  --border-design-rgb-9: var(--gray-rgb-9);

  --black: #000;
  --black-rgb: 0, 0, 0;
  --white: #fff;
  --white-rgb: 255, 255, 255;

  --status-info-light: #fff9db;
  --status-info-light-rgb: 255, 249, 219;
  --status-success: #62d957;
  --status-success-rgb: 98, 217, 87;
  --status-warning: #ff7700;
  --status-warning-rgb: 255, 119, 0;
  --status-error: #ff3634;
  --status-error-rgb: 255, 54, 52;

  --link-color: var(--brand-1);

  --brand-wechat: #12d26a;
  --brand-alipay: #1677ff;

  // Box Shadow
  --shadow-color-1: rgba(0, 0, 0, 0.04);
  --shadow-color-2: rgba(0, 0, 0, 0.08);
  --shadow-color-3: rgba(0, 0, 0, 0.08);

  --gutter: 12px;
  --btn-primary-border-color: transparent;
  --btn-primary-bg: linear-gradient(90deg, var(--brand-2) 0%, var(--brand-1) 98%);
  --btn-primary-color: var(--white);


}

::-webkit-scrollbar {
  --bar-width: 5px;
  width: var(--bar-width);
  height: var(--bar-width);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 20px;
  background-clip: content-box;
  border: 1px solid transparent;
}

.textLayer {
  user-drag: none;
  -webkit-user-drag: none;
  
  *::selection {
    color: transparent;
    background: transparent;
  }
}

.pdfjs-highlight-contextmenu,
.pdfjs-highlight-areas {
  position: absolute;
  width: 100%;
  top: 12px;
  left: 12px;
  height: unset;
}

.pdfjs-highlight-right {
  position: absolute;
  pointer-events: none;
  background-color: #0081ff;
  height: 25px;
  width: 25px;
  z-index: 20;
  border-radius: 0 50% 50% 50%;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
  border: solid 1px #fff;
  z-index: 45;
}

.pdfjs-highlight-left {
  position: absolute;
  pointer-events: none;
  background-color: #0081ff;
  height: 25px;
  width: 25px;
  z-index: 100;
  border-radius: 50% 0 50% 50%;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
  border: solid 1px #fff;
  z-index: 45;
}


// chat插件页面样式
.guess-you {
  max-width: 920px;
  margin: 0 auto;
  padding: 0 10px;
}

// inputToolbar按钮样式
.input-tools-btn {
  margin: 0 5px;
  background: none !important;
  border: 1px solid rgba(var(--border-design-rgb-2), 0.5) !important;
}

// 弹框类圆角
.ant-modal-content {
  border-radius: 20px !important;
}

.ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}

.ant-dropdown-menu {
  border-radius: 8px !important;
}

@primary-color: #08979c;@font-size-base: 14px;