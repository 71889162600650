.pptModal :global {
  .ant-modal-body {
    position: relative;
    background-image: url('../../../../../assets/pptbg.webp');
    background-size: cover;
  }
  .ppt-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
    cursor: pointer;

    .progress-bar {
      width: 90%;
      .ant-progress-text {
        color: #fff;
      }
    }
  }
}

.templatePage :global {
  width: 1040px;
  height: 720px;
  background-color: #fff;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .spin {
    height: 100%;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  
  .page-header {
    height: 60px;
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(166, 166, 166,0.2);
    span {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 28.96px;
      color: rgba(56, 56, 56, 1);
    }
  }
  .page-footer {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
  }

  .page-content {
    width: 100%;
    height: calc(100% - 120px);
    padding: 10px;
    // 模版列表
    .template-list {
      width: 100%;
      height: calc(100% - 135px);
      overflow-y: auto;
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-gap: 10px;
  
      .template {
        margin: 8px;
        width: 220px;
        height: 140px;
        cursor: pointer;
        overflow: hidden;
        border-radius: 12px;
        background-color: #ecedf4;
        border: 2px solid transparent;
        img {
          width: 100%;
          height: 100%;
          opacity: 1;
          object-fit: cover;
          transition: all .6s ease;
  
          &:hover {
            transform: scale(1.4);
            transition-duration: .3s;
          }
        }
        &:hover {
          border: 3px solid var(--brand-1);
        }
      }
  
      .templateSelected {
        border: 3px solid var(--brand-1);
      }
    }
    // 选项
    .template-options {
      position: absolute;
      bottom: 53px;
      width: 100%;
      background-color: #fff;
      padding-top: 5px;
      .template-option {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .name {
          width: 7%;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 18.48px;
          color: rgba(56, 56, 56, 1);
        }
  
        .option-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .option-item {
            height: 29px;
            padding: 0 8px;
            line-height: 29px;
            border-radius: 5px;
            cursor: pointer;
            color: rgba(56, 56, 56, 1);
            &.active {
              background: var(--brand-1);
              color: #fff;
            }
          }
        }
  
        .theme-color-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 16px;
          .theme-color-item {
            width: 26px;
            height: 26px;
            border-radius: 4px;
            cursor: pointer;
  
            &:hover {
              border: 2px solid var(--brand-1);
            }
  
            &.active {
              border: 2px solid var(--brand-1);
            }
          }
          
          .theme-color-item-all {
            background-image: url(../../../../../assets/colorful.png);
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        .theme-color-list div:nth-child(10) {
          border: 1px solid #E5E5E5;
        }
      }
    }
  }
 }


 .generatePage :global {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  padding: 20px 30px;

  .left {
    width: 1050px;
    height: 668px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    .left-svg {
      width: 100%;
      height: calc(100% - 40px);
      overflow: hidden;
    }
    .page {
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 0;
      text-align: center;
      font-size: 15px;
      font-weight: 700;
      color: rgba(56, 56, 56, 1);
    }
  }

  .right {
    width: 554px;
    height: 781px;
    // height: 588px;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    position: relative;
    .title {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 26px;
      margin-bottom: 10px;
      color: rgba(56, 56, 56, 1);
    }
    .btns {
      width: 80%;
      height: 48px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      .btns-item {
        height: 48px;
        opacity: 1;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 48px;
        color: #fff;
        text-align: center;
      }
      .generate {
        background-color: rgb(255, 141, 26);
      }
      .download {
        background-color: rgb(37, 111, 255);
        cursor: pointer;
      }
    }
    .right-perview {
      width: 100%;
      height: calc(100% - 100px);
      overflow-y: auto;
      .right-perview-content {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
        grid-gap: 10px;
        .ppt-item {
          border: 1px solid rgba(166, 166, 166,0.2);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 10px;
          &.active {
            border: 1px solid var(--brand-1);
          }
        }
        .ppt-item-img {
          height: 81px;
          width: 144px;
        }
      }
    }
  }
  
 }

 @media screen and (max-width: 1440px) {
  .generatePage :global {
    .left { 
      width: 840px;
      height: 530px;
    }
    .right {
      height: 588px;
    }
  }
  
 }

 @media screen and (min-width: 864px) {
  .generatePage :global {
    .left { 
      transform: scale(0.85);
    }
    .right {
      transform: scale(0.8);
    }
  }
  
 }

@primary-color: #08979c;@font-size-base: 14px;