@import "@/components/common/var.less";

.header :global {
  display: flex;
  position: relative;

  .head-right {
    display: flex;
    align-items: center;
    height: 55px;
    font-size: @font-size-lg;
    flex: 1;
    width: calc(100% - 300px);

    .ant-segmented {
      height: 42px;
      padding: 4px;
      border-radius: 5px;
      user-select: none;
      overflow: hidden;
      transition: all 0.3s ease;

      .ant-segmented-group {
        height: 100%;
        .ant-segmented-item {
          width: 125px;
          border: @border-width solid transparent;
          display: none;

          .ant-segmented-item-label {
            height: 32px;
            line-height: 32px;
            padding: 0;
          }

          &:hover {
            border-radius: 5px;
          }
        }

        .ant-segmented-item-selected {
          border-radius: 5px;
          display: unset;
        }
      }

      .ant-space {
        .ant-space-item {
          display: flex;
          align-items: center;
          font-size: @font-size-xs;
        }
      }

      &:hover {
        width: 700px;

        .ant-segmented-item {
          display: unset;
        }
      }
    }

    #segmented {
      width: 700px;

      .ant-segmented-item {
        display: unset;
      }
    }

    .mobile-head-sider {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
  
  .coupon {
    position: absolute;
    left: 170px;
    z-index: 100;
    .special-button {
      img {
        width: 50px;
      }
    }
  }
 

  @media only screen and (max-width: 768px) {
    .full-width {
      width: 100%;
      flex: 1;
    }
    .zero-width {
      width: 0;
      display: none;
    }
    .head-right {
      font-size: 14px;
    }
  }
}

.settingbtn :global {
  .ant-modal-body {
    padding-bottom: 0;
  }
  .anticon[aria-label="team"] {
    padding: 8px;
    font-size: @font-size-md;
    position: absolute;
    right: -32px;
  }
  .ant-space {
    width: 100%;
    .tip {
      color: rgba(var(--text-design-rgb-3), 1);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
  .ant-popover {
    width: 100%;
  }
  img {
    width: 100%;
    background-color: var(--brand-1);
  }
}

.tourModelContainer :global {
  width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;

  .tour-model-segmented {
    padding: 4px;
    color: rgba(0, 0, 0, 0.65);
    background-color: rgba(0, 0, 0, 0.04);
    // width: 133px;
    height: 42px;
  }
  
  .tour-model-segmented-content {
    padding: 0 10px;
    height: calc(100% - 0px);
    width: calc(100% - 0px);
    background-color: #ffffff;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  }
}

.tourModelMobileContainer :global {
  position: absolute;
  left: 40px;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 0 0 0 .4px #60a5fa;
  padding: 2px 8px;
}

.tourModelPopoverOverlay :global {
  margin-top: -16px !important;
  padding-top: 0 !important;

  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.tourModelPopover :global {
  padding: 16px;
  
  .tour-model-popover-grids {
    padding-right: 16px;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  .tour-model-popover-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    padding-top: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:nth-child(2) {
      > div {
        width: 110px;
      }
    }

    > div {
      width: 80px;
      text-align: center;
      margin: 3px 0 2px;
      font-size: 13px;
    }

    &.tour-model-popover-item-selected {
      background-color: rgba(var(--brand-rgb-1), 0.2) !important;
    }
  }

  .tour-model-popover-header {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid #ececec;
    margin-top: 8px;
  }

  .tour-model-popover-tips {
    width: 312px;
    padding: 12px 0 0 0;
    line-height: 25px;
    height: 162px;
  }
}

.tourModelSegmented :global {
  font-size: 14px;
  border-radius: 5px;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;

  .tour-model-segmented-content {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    .ant-avatar {
      margin-right: 4px;
    }

    .anticon {
      margin-left: 8px;
      color: #666666;
    }
  }
}


@media screen and (max-width: 992px) {
  .header {
    background: linear-gradient(#a6ccfc, #c1dbfd, #e4f0fe);
    border: 1px solid #ffffff;
  }
}

@primary-color: #08979c;@font-size-base: 14px;