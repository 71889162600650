@import "@/components/common/var.less";

.chatWindow :global {
  margin: 0 auto;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    .PhraseBookContainer, .chatBottomContainer {
      transform: translate(28px, 0px); // 输入框偏移与message对齐
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;