@import "@/components/common/var.less";

.FloatSider :global {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 75px;
  width: 55px;
  background-color: transparent;
  
  .float-sider-container {
    position: relative;
    height: 210px;
    border-top: 1px solid var(--brand-1);
    border-left: 1px solid var(--brand-1);
    border-bottom: 1px solid var(--brand-1);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    overflow: hidden;
  }

  .float-sider-handle {
    color: var(--brand-1);
    margin-left: 14px;
    cursor: pointer;
  }

  .float-sider-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    cursor: pointer;
    position: relative;

    &:not(:first-child):before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 10%;
      width: 80%;
      height: 0;
      border-bottom: 1px solid var(--brand-1);
    }

    &.ant-tooltip-open + .float-sider-item:before {
      opacity: 0;
    }

    &:hover {
      background-color: var(--brand-1);


      .icon,
      .label {
        color: var(--white);
      }
    }

    .icon,
    .label {
      color: var(--brand-1);
      pointer-events: none;
      font-size: 12px;
    }

    .icon {
      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.FloatSider {
  &:hover {
    .float-sider-handle {
      display: block;
    }
  }
}

[data-theme="light"] {
  .FloatSider .float-sider-container {
    background-color: rgba(var(--bg-design-rgb-9), 1);
  }
}

[data-theme="dark"] {
  .FloatSider .float-sider-container {
    background-color: rgba(var(--bg-design-rgb-6), 0.1);
  }
}

@primary-color: #08979c;@font-size-base: 14px;