@import "@/components/common/var.less";

.celebrityUpload :global {
  width: 100%;
  .ant-col-6 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
    gap: 4px;
    .anticon-question-circle {
      cursor: help;
    }
  }
  .ant-col-16 {
    display: flex;
    align-items: center;
    .ant-select {
      flex: 1;
    }
  }
  .dropdown-wrapper {
    width: 100%;
    > span {
      display: flex;
      width: 100%;
      .ant-upload {
        flex: 1;
      }
    }
  }
}

.customToolbarUpload {
  :global {
    .ant-upload .ant-btn{
      border-radius: 0px;
      color: var(--brand-1);
      background: none;
      opacity: 1;
      &:hover {
        color: var(--brand-1);
        background: rgba(0, 0, 0, 0.018);
        border-color: transparent;
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;