.PhraseBookContainer :global{
  width: 100%;
  height: 194px;
  margin-bottom: 10px;
  

  .PhraseBookContent {
    max-width: 884px;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 0px 0.6px var(--input-boder-color);
    margin: 0 auto;
    background: rgba(96, 165, 250, 0.2);
    padding: 10px 20px;
    display: grid;
    grid-template-rows: 20px 1fr 20px;
  }
  
  .PhraseBookContentHeader {
    display: flex;
    justify-content: space-between;

    .PhraseBookContentTitle {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(27, 124, 252, 1)
    }

    svg {
      cursor: pointer;
    }
  }

  .PhraseBookContentBody {
    overflow-x: hidden;
    .PhraseBookContentItem {
      left: 14px;
      top: 41px;
      width: calc(100% - 10px);
      padding: 2px;
      height: 40px;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 18.82px;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.8);

        .custom-badge { 
          
        }
      }
      
      .custom-badge {  
        .ant-badge-status-dot {
          width: 12px;
          height: 12px;
        }
        
      }

      .title {
        color: rgba(56, 56, 56, 1);
        white-space: nowrap;
      }
      .content {
        width: 100%;
        color: rgba(128, 128, 128, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .PhraseItemAction {
        display: flex;
        align-items: center;
        z-index: 99;
      }
    }

    .ant-empty-description {
      color: rgba(128, 128, 128, 1);
    }
  }

  .PhraseBookContentFooter {
    .footerBtn {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(27, 124, 252, 1);
      cursor: pointer;
    }
  }
}


.phraseEditModal :global{
  
  .ant-modal-header {
    border: none;
    .ant-modal-title {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 26.06px;
      color: rgba(27, 124, 252, 1);
    }
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-footer {
    border: none;
  }
  
  .PhraseEditContainer {
    height: 100%;
    margin: 0 20px;
    padding: 5px;
    border-radius: 10px;
    background: rgba(96, 165, 250, 0.2);
    border: 1px solid rgba(96, 165, 250, 0.2);

    .PhraseEditInputWrapper {
      position: relative;
      display: inline-block;
      line-height: 32px;
      width: 100%;
      overflow: hidden;

      > span {
        position: absolute;
        line-height: 32px;
        display: inline-block;
        margin-left: 5px;
        color: rgba(var(--text-design-rgb-2));
        font-weight: bold;
        z-index: 1;
      }
    }

    .PhraseEditInput {
      margin-bottom: 10px;
      border-radius: 5px;
      padding-left: 50px;
    }
    .PhraseEditTextarea {
      text-indent: 45px;
      border-radius: 5px 5px 8px 8px;
      padding-left: 5px;
    }
    .PhraseEditInput,
    .PhraseEditTextarea {
      &:focus {
        background-color: rgba(255,255,255,0.5);
      }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;