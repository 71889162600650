@import "@/components/common/var.less";

.googleBubble :global {
  width: 100%;
  background-color: unset;

  .ListItem {
    background-color: transparent;

    &:first-child {
      background-color: unset;
    }
    &:last-child {
      background-color: unset;
    }
  }

  .result-reply {
    padding: 0 10px;
    border-top: unset !important ;
  }
  .widthStyle {
    width: calc(100vw - 93px);
  }
  .googleCard {
    white-space: pre-wrap;
    hyphens: auto;

    .header {
      font-size: 16px;
      padding: 0 4px;
      display: flex;
      justify-content: space-between;
      gap: @gutter;
      cursor: pointer;

      .title {
        flex: 1;
        color: var(--brand-1);
        cursor: pointer;
        white-space: pre-wrap;
      }
    }

    .ant-descriptions {
      .ant-descriptions-header {
        margin-bottom: 0;
        .ant-descriptions-title {
          cursor: pointer;
          margin-right: 8px;

          &:hover {
            text-decoration: underline;
            text-decoration-color: var(--brand-1);
          }
        }
        .ant-typography {
          color: var(--brand-2);
          white-space: break-spaces;
        }
        .ant-descriptions-extra {
          .ant-dropdown-button {
            .ant-btn {
              background-color: var(--brand-2);
              height: 36px;
              color: var(--white);
              font-size: 16px;
              border-radius: 4px;
              padding: 4px 8px;

              .ant-dropdown-menu {
                padding: 0;

                .ant-dropdown-menu-item {
                  padding: 0;
                }
              }

              &:hover {
                border: @border-width solid var(--brand-2);
              }

              .disabled {
                &:hover {
                  background-color: var(--brand-2);
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }
      .ant-descriptions-item {
        padding-bottom: 0;
        .ant-descriptions-item-label {
          color: rgba(var(--text-design-rgb-3), 1);
          display: flex;
          width: 70px;

          .label-name {
            flex: 1;
            text-align-last: justify;
          }
        }
        &:not(.abstract) .ant-descriptions-item-content {
          color: rgba(var(--text-design-rgb-2), 1);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        .ant-typography {
          margin-bottom: 0;
          color: rgba(var(--text-design-rgb-2), 1);
        }
      }
    }

    .intruduce {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 4px;
      font-size: 14px;
      line-height: 14px;
      gap: 8px;

      .auth-and-journal {
        flex: 1;
        padding: 2px 0;
        white-space: pre-wrap;
        color: rgba(var(--text-design-rgb-2), 0.80);
      }

      .citations {
        padding: 2px 0;
        color: rgba(var(--text-design-rgb-3), 1);
        display: flex;
        justify-content: baseline;
      }
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
    .content {
      padding: 0 4px;
      line-height: 16px;
      color: rgba(var(--text-design-rgb-3), 0.80);
    }
  }

  .ant-typography {
    margin-bottom: 0;
    color: rgba(var(--text-design-rgb-2), 1);
  }
  .ant-typography-expand {
    color: var(--link-color);
  }
}

@primary-color: #08979c;@font-size-base: 14px;