.historyItem:hover :global {
  background: rgba(232, 242, 255, 1);
}

.historyItem :global {
  border-radius: 10px;
  background: rgb(var(--background-color-rgb));
  box-shadow: 1px 1px 6px 2px rgba(166, 166, 166, 0.1);
  margin-bottom: 6px;
  min-height: 50px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  .historyItemHeader {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.with-content {
      padding-top: 12px;
    }
  }

  .historyItemHeaderLeft {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .historyItemIcon {
    display: inline-flex;
    width: 32px;
    text-align: center;
    flex-flow: column;
    align-items: center;

    svg {
      width: 16px;
      height: auto;
    }
  }

  .historyItemType {
    font-weight: bold;
  }

  .historyItemTitle {
    color: rgb(var(--text-design-rgb-1), 0.7)
  }
  
  .historyItemDivider {
    margin: 5px;
    display: inline-block;
    width: 1px;
    height: 16px;
    background-color: rgb(var(--gray-rgb-5));
  }

  .historyItemHeaderRight {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-right: 16px;
  }

  .historyItemTime {
    font-size: 12px;
    color: rgba(var(--text-design-rgb-3), 0.7);
  }

  .historyItemOpt {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    > span {
      display: inline-block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(var(--bg-design-rgb-1), 0.1);
      }
    }
  }

  .historyItemMedia {
    display: flex;
    padding-left: 8px;
    flex-flow: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0 16px 0;

    .historyItemImg {
      border-radius: 10px;
      min-width: 120px;
      height: 80px;
      background-color: rgba(var(--bg-design-rgb-3), 0.2);
      text-align: center;
    }

    img {
      border-radius: 10px;
      height: 80px;
    }
  }

  .historyItemAttach {
    display: inline-block;
    max-width: 230px;
    min-width: 150px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(188, 188, 188, 1);
    border-radius: 5px;

    .historyItemFile {
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      height: 44px;
      padding: 8px;

      svg {
        height: 16px;
      }
    }
  }

  .historyItemMore {
    color: rgba(var(--bg-design-rgb-3), 1);
    background-color: rgba(188, 188, 188, 0.5);
    border-radius: 5px;
    padding: 3px 8px;
  }

  .historyItemTitleEdit {
    display: flex;
    flex-flow: row;
    align-items: center;

    input {
      margin-right: 12px;
      width: 400px;
      border-radius: 5px;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;