.textSpinner :global {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: #333333;
  box-sizing: border-box;
  animation: text-flash 1.2s linear infinite;
  transform-origin: center;
  transform: scale(0.5);
}


@primary-color: #08979c;@font-size-base: 14px;