.editorContent :global {

}

.editorHeader :global {
  margin-bottom: 16px;
  padding: 10px 5px;
}

.editorParagraph :global {
  position: relative;

  .editorParagraphContent {
    padding-bottom: 10px;
    position: relative;
    padding-left: 5px;

    .editorParagraphAction {
      position: absolute;
      z-index: 1;
      right: 6px;
      top: 5px;

      .ant-btn {
        border: none;
        padding: 0 1px;
        &:hover {
          border: none;
        }
      }

      .ant-btn:not(:last-child) {
        margin-right: 3px;
      }
    }
  }

}

@primary-color: #08979c;@font-size-base: 14px;