.evidenceSelectMenu :global {
  width: 180px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--bg-design-rgb-9), 1);
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px;

  .menuTitle {
    font-weight: 900;
  }
}

@primary-color: #08979c;@font-size-base: 14px;