@import "@/components/common/var.less";

.leftMenu :global {
  width: 59px;
  min-width: 59px;
  height: 100%;
  background-color: rgba(229, 229, 229, 0.5);
  position: relative;

  .leftMenu-logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
    }
  }

  .leftMenu-main {
    text-align: center;
    height: 86%;
    .leftMenu-main-item {
      height: 59px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: rgba(var(--text-design-rgb-1), 0.9);
      font-weight: 600;
      color: rgba(var(--gray-rgb-1), 0.6);


      &.active {
        color: var(--brand-1);
        // background-color: rgba(var(--brand-rgb-1), 0.25);
      }
      
      &:hover {
        color: rgba(var(--brand-rgb-1), 0.85);
      }
    }
  }
}

[data-theme="dark"] {
  .leftMenu :global {
    background-color: rgba(16, 16, 16, 1);
  }
}

@primary-color: #08979c;@font-size-base: 14px;