.react-draggable-dragging,
.react-draggable-dragging .float-sider-container,
.react-draggable-dragging .float-sider-item {
  cursor: move !important;
}

.floatSider {
  transition: right 0.8s ease;

  &.close {
    right: -55px;
  }

  .float-sider-handle {
    visibility: hidden;
    transform: translateX(0);
    transition: transform 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:not(.close) .float-sider-handle {
    border-radius: 100%;
    border: 1px solid var(--brand-1);
    width: 26px;
    height: 26px;
    
    &:hover {
      background-color: var(--brand-1);
      color: var(--white);
    }
  }

  &.close .float-sider-mini {
    width: 25px;
    background: var(--brand-1);
    border-radius: 4px 0 0 4px;
    color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    height: 26px;
    transform: translateY(70px);

    .anticon  {
      font-size: 12px;
    }
  }
  
  &.close,
  &:hover {
    .float-sider-handle {
      visibility: visible;
    }
  }
  &.close {
    .float-sider-handle {
      transform: translateX(-46px);
      transition-delay: 0.8s;
    }
  }
  &.collapsed {
    .float-sider-mini {
      visibility: visible;
      height: 70px;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;