@import "@/components/common/var.less";

.sessionList :global {
  flex: 1;
  display: flex;
  flex-direction: column;
  .session-list-hd {
    padding: 15px 20px 16px 20px;
    background-color: rgba(var(--sider-background-color-rgb), 0.7);
    .search-and-create {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 0;
      position: relative;

      > :nth-child(1) {
        position: absolute;
        z-index: 2;
        top: 8px;
        left: 6px;
        color: rgba(var(--gray-rgb-4));
      }
      > :nth-child(2) {
        flex: 1;
      }
      .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }
      .ant-input-search > .ant-input-group .ant-input {
        padding-left: 16px;
      }
      .ant-input-search > .ant-input-group > .ant-input-group-addon{
        display: none;
      }
      .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
        border-left: none;
        &:hover {
          border-color: transparent;
          background-color: transparent;
        }
      }

      .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        left: 0;
      }
      
      .create-session-btn {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }

      .ant-dropdown-button {
        width: 100px;
        .ant-btn-compact-first-item {
          padding: 4px 8px;
        }
        .ant-dropdown-trigger {
          width: 26px;
          .ant-dropdown-menu-item {
            padding: 0 8px;
          }
        }
      }
    }
  }

  .scrolling-bottom {
    .session:last-child {
      &:after {
        content: '人家也是有底线的啦 @^_^@ ';
        position: absolute;
        padding: 10px 5px;
        // bottom: 0;
        left: 50%;
        transform: translate(-50%, 40px);
        color: rgba(var(--text-design-rgb-2), .35);
        width: 100%;
        text-align: center;
      }
    }
  }

  .session-list-bd {
    flex: 1;
    // background-color: rgba(var(--sider-background-color-rgb), 0.7);
    .session {
      height: 56px;
      color: rgba(var(--text-design-rgb-2), 1);
      padding: 4px 0;
      box-sizing: border-box;
      border-radius: 10px;
      border: @border-width solid transparent;
      margin: 1px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      cursor: pointer;

      // &:last-child {
      //   border-bottom: @border-width solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1));
      // }

      &.selected {
        background-color: rgba(var(--brand-rgb-1), 0.2);
      }

      &.bulk {
        margin: 0 !important;
      }

      .title-contain {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        gap: 7px;
        height: 28px;
        line-height: 28px;
        margin-bottom: 2px;

        .title {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          user-select: none;
        }

        .title-icon-border {
          padding: 1px;
          // border: 1px solid rgba(var(--black-rgb), 0.15);
          // box-shadow: 0 4px 8px 0 var(--shadow-color-2);
          border-radius: 2px;
          width: 1.2rem;
          height: 1.2rem;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
        }
      }

      .session-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: rgba(var(--text-design-rgb-4), 1);

        .session-scenes {
          color: rgba(var(--text-design-rgb-3), 0.80);
          background: rgba(var(--bg-design-rgb-5), 0.40);
          border-radius: 4px;
          padding: 0px 7px;
        }

        .session-create-at {
          margin-right: 15px;
        }
      }

      .ant-input-search {
        input {
          padding: 0;
          height: 32px;
          line-height: 32px;
        }
        .ant-input-group-addon {
          border-radius: 10px;
          button {
            border-color: rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
            // margin-left: 4px;
            &:hover {
              // color: var(--brand-1);
              border-color: var(--brand-1);
            }
          }
        }
      }

      .ant-input-search
        .ant-input:hover
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary),
      .ant-input-search
        .ant-input:focus
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary) {
        border-color: rgba(var(--border-design-rgb-5), var(--border-design-opacity-1));
      }

      &.active {
        // border: @border-width solid var(--brand-1);
        background-color: rgba(var(--brand-rgb-1), 0.2) !important;
      }

      &.disable {
        cursor: default;
        color: rgba(var(--text-design-rgb-5), 1);
      }

      // &:hover {
      //   border: @border-width solid var(--brand-1);
      //   background-color: rgba(var(--brand-rgb-1), 0.2) !important;
      // }
    }

    .spin, .chat-session-loading {
      text-align: center;
    }
    .ant-empty {
      margin: 0 11px 0 12px;
    }
  }
}

.sessionStatistics :global {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  overflow: hidden;
  opacity: 1;
  
  .left {
    cursor: default;
  }

  .count {
    color: var(--brand-1);
    margin: 0 5px;
    font-weight: bold;
  }

}

[data-theme="light"] {
  .sessionStatistics {
    background-color: rgba(var(--sider-background-color-rgb), 0.7);
  }
}

[data-theme="dark"] {
  .sessionStatistics {
    background: rgba(var(--bg-design-rgb-6), 0.1);
    // border-top: @border-width solid rgba(var(--border-design-rgb-6), var(--border-design-opacity-1));
  }
}


@primary-color: #08979c;@font-size-base: 14px;