.VideoBubble :global {
  video {
    width: 506px;
    height: 285px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    margin: 12px 0;
  }

  .video-buttons {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    align-items: start;
    justify-content: start;
    .download-button {
        border-radius: 30px;
        font-size: 12px;
        padding: 2px 16px;
        line-height: 18px;
        border-color: var(--brand-1);
        font-weight: 600;
        letter-spacing: 2px;
        svg {
          width: 14px;
          height: 14px;
          vertical-align: text-bottom;
          opacity: 0.75;
        }
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;