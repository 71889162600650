.editorParagraph {

  &.h4 {
    margin-bottom: 10px;
  }
  
  .editorParagraphContent {
    padding-top: 6px;
  }

  &.h5 {
    .editorParagraphContent {
      // padding-left: 30px;
    }
  }

  &.h6 {
    .editorParagraphContent {
      // padding-left: 60px;
    }
  }

  .editorParagraphAction {
    visibility: hidden;
  }

  .editorParagraphContent:hover > .editorParagraphAction {
    visibility: visible;
  }
}

@media only screen and (max-width: 768px) {
  .editorParagraphAction {
    position: initial !important;
    text-align: right;
    padding: 5px;
  }
  
  .editorParagraph .editorParagraphAction {
    visibility: visible;
  }
}

@primary-color: #08979c;@font-size-base: 14px;