.sessionItemAction :global {
  .ant-dropdown-menu-item {
    padding: 0;
  }
  button {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    height: 32px;
    > :first-child {
      align-items: center;
    }
  }
}

@primary-color: #08979c;@font-size-base: 14px;